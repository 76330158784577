/** dev  */
// const FIREBASE_CONFIG = {
//   apiKey: "AIzaSyCrRsn-h8JNT9zmLRLj4NcUkZNuZZtKbKg",
//   authDomain: "cvtdev.firebaseapp.com",
//   databaseURL: "https://cvtdev.firebaseio.com",
//   projectId: "cvtdev",
//   storageBucket: "cvtdev.appspot.com",
//   messagingSenderId: "648137259344",
//   appId: "1:648137259344:web:ec62c56c81237b4c891455"
// };

/** prod */
const FIREBASE_CONFIG = {
  apiKey: "AIzaSyDqMzKgd75FNO5R1OxDM8-ucWAVUZq6U2Q",
  authDomain: "test-dd778.firebaseapp.com",
  databaseURL: "https://test-dd778.firebaseio.com",
  projectId: "test-dd778",
  storageBucket: "test-dd778.appspot.com",
  messagingSenderId: "598431926470",
  appId: "1:598431926470:web:2364e3bb2d23c9e9",
};

// dev
// export const analytics = {
//   clientId: "648137259344-ebscq4foe9ntjs10t975bnr93k4vmr8e.apps.googleusercontent.com",
//   propertyId: 357782223,
// } 

/** prod */
export const analytics = {
  clientId:
    "598431926470-bl20vcrc8lpb9o5kpe6mp1kc836domff.apps.googleusercontent.com",
  viewId: "200754430",
  propertyId: 367800685,
};

// export const credentialsAnalytics = {
//   web: {
//     client_id:
//       "648137259344-e7tphae7tf0aqh9k9qov2q1jfrc6dtv4.apps.googleusercontent.com",
//     project_id: "cvtdev",
//     auth_uri: "https://accounts.google.com/o/oauth2/auth",
//     token_uri: "https://oauth2.googleapis.com/token",
//     auth_provider_x509_cert_url: "https://www.googleapis.com/oauth2/v1/certs",
//     client_secret: "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",
//     redirect_uris: ["http://localhost"],
//     javascript_origins: ["http://localhost"],
//   },
// };

// dev
// export const development_plan_id = "P-7UW47637TD433542PMHQLVGY"
// export const development_annual_plan_id = "P-53C637537A179523SMHQLX2Y"
// prod
export const development_plan_id = "P-8EL1344067174810VLVQELMY";
export const development_annual_plan_id = "P-66041325UB7548120MD3SK5Q";

export default FIREBASE_CONFIG;
