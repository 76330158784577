/** Firebase Config */
import firebase from "firebase";
import FIREBASE_CONFIG from "./keys";

  // Initialize Firebase
  const initApp = () => {
    const app = firebase.initializeApp(FIREBASE_CONFIG);

    /**
     * Use emulators for local development
     * @NOTE In order to use emulators other than functions 
     * emulator, you need to upgrade the firebase package
     * to at least version 8.0.0. `npm i firebase@8`.
     * If the current project is already using Firebase 
     * v8 or higher please remove this comment.
     */
    
    // app.functions().useEmulator('localhost', 5001);
    // app.firestore().useEmulator('localhost', 8888);
    // app.auth().useEmulator('http://localhost:9099');
    // app.storage().useEmulator('localhost', 9199);
  };

  export default initApp;