import React, { Component, createRef } from "react";
import TablaSimple from "./TablaSimple";
import { analytics } from "../../../resources/config/keys";
import { getAllUsers } from "../../../service/users"
import CurrentUser from "../../public/core/PublicUserSessionContext";
import { GRATIS, PREMIUM, ANALYTICS_USER_FILTER_OPTIONS as opciones, ANALYTICS_USER_FILTER_MAP as mapaFiltro } from "../../../service/constants";
import { getProcedure, getProcedures } from "../../../service/procedures";
import GoogleButtonLike from "../../commons/GoogleButtonLike";
import { GoogleAnalyticsReportSDK } from "../../../lib/google-analytics";
import { VideoViewsCountReport } from "../../../lib/reports/VideoViewsCountReport";
import { DiplomadoPreviewCountReport } from "../../../lib/reports/DiplomadoPreviewCountReport";
import { DownloadedFasciculesCountReport } from "../../../lib/reports/DownloadedFasciculesCountReport";
import { MostDownloadedFasciculesReport } from "../../../lib/reports/MostDownloadedFasciculesReport";
import { MostViewedVideosReport } from "../../../lib/reports/MostViewedVideosReport";
import { GoogleService } from "../../../lib/google-service";

class StatisticsComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allUsers: [],
            allProcedures: [],
            usersPremium: 0,
            usersPremiumAnual: 0,
            usersGratis: 0,
            filterUserTypes: opciones[0],
            error: "",
            signedIn: false,
            datosVideos: [],
            datosFasciculos: [],
            videosVistos: 0,
            fasciculosDescargados: 0,
            previewPage: 0
        };
        this.googleService = new GoogleService({
            clientId: analytics.clientId
        })
        this.googleButton = createRef();
    }
    componentDidMount() {
        this.getInitialData().then(() => {
            this.iniciarAnalytics();
        });
    }
    getInitialData = async () => {
        this.context.iniciaCarga()
        let allUsers = await getAllUsers()
        let allProcedures = await getProcedures()

        let gratis = allUsers.filter(user => user.type === GRATIS)
        let premium = allUsers.filter(user => user.type === PREMIUM)
        let premiumAnual = allUsers.filter(user => user.hasPremiumAnual)

        let [usersGratis, usersPremium, usersPremiumAnual] = [gratis.length, premium.length, premiumAnual.length]
        this.setState({ allUsers, usersGratis, usersPremium, usersPremiumAnual, allProcedures })
        this.context.terminaCarga()
    }
    findProcedure = (procedureID) => this.state.allProcedures.find(el => el.id === procedureID) || null

    iniciarAnalytics = () => {
        // @ts-expect-error
        this.context.iniciaCarga();
        
        try {
            this.googleService.initializeAuth({ 
                buttonElement: this.googleButton.current
            });

            this.googleService.on("signIn", () => {
                this.handleUserSignIn();
            });

            this.googleService.on("signOut", () => {
                this.handleUserLogout();
            });

            // In case has previously signed in.
            if (this.googleService.userIsSignedInGoogleServices()) {
                this.handleUserSignIn();
            }
        } finally {
            // @ts-expect-error
            this.context.terminaCarga();
        }
    }

    handleUserSignIn = () => {
        this.setState({ signedIn: true });
        this.cargarDatosGA();
    }
    
    handleUserLogout() {
        this.googleService.logout();
        this.setState({ signedIn: false });
    }

    cargarDatosGA = async (filterUserTypes = this.state.filterUserTypes) => {
        // @ts-expect-error
        this.context.iniciaCarga();

        try {
            const googleAnalytics = new GoogleAnalyticsReportSDK({
                credentials: this.googleService.getGoogleClientAccessToken() ?? "",
                property: analytics.propertyId,
            });

            const [
                videoCount, 
                diplomadosCount, 
                downloadedFasciulesCount, 
                fasciculesData, 
                videosData
            ] = await Promise.all([
                VideoViewsCountReport.make(googleAnalytics).startDate("2015-08-14").endDate("today").run(),
                DiplomadoPreviewCountReport.make(googleAnalytics).startDate("2015-08-14").endDate("today").run(),
                DownloadedFasciculesCountReport.make(googleAnalytics).startDate("2015-08-14").endDate("today").run(),
                MostDownloadedFasciculesReport.make(googleAnalytics).startDate("2015-08-14").endDate("today").run(),
                MostViewedVideosReport.make(googleAnalytics).startDate("2015-08-14").endDate("today").run(),
            ]);
            
            this.setVideosCount(videoCount);
            this.setPreviewPageCount(diplomadosCount);
            this.setFasciculesCount(downloadedFasciulesCount);
            this.setVideosData(videosData);
            this.setFasciculesData(fasciculesData);
        } catch (e) {
            // Possibly a 401 error due to token expiration. Forcing logout and re-login
            console.error("Error running Google Analytics report:", e);
            this.handleUserLogout();
        } finally {
            // @ts-expect-error
            this.context.terminaCarga();
        }
    }

    /**
     * @param {number} videosVistos
     */
    setVideosCount(videosVistos) {
        this.setState({ videosVistos });
    }

    /**
     * @param {number} fasciculosDescargados
     */
    setFasciculesCount(fasciculosDescargados) {
        this.setState({ fasciculosDescargados });
    }

    /**
     * @param {number} previewPage
     */
    setPreviewPageCount(previewPage) {
        this.setState({ previewPage });
    }

    /**
     * @param {Array<import("src/lib/reports/MostViewedVideosReport").Video>} videos
     */
    setVideosData(videos) {
        let allProcedures = videos.map(row => this.findProcedure(row.id));
        let filtered = allProcedures.filter(el => (el && el !== null));

        console.log({
            allProcedures,
            videos,
        })

        let datosVideos = filtered.map((procedure, index) => {
            const match = videos.find(video => video.id === procedure.id);
            return {
                name: procedure.name,
                id: match.id,
                id2: procedure.id,
                amount: match.views
            }
        })

        this.setState({ datosVideos });
    }

    /**
     * @param {Array<import("src/lib/reports/MostDownloadedFasciculesReport").Fascicule>} fascicules
     */
    setFasciculesData(fascicules) {
        let allProcedures = fascicules.map(row => this.findProcedure(row.id));
        let filtered = allProcedures.filter(el => (el && el !== null));

        let datosFasciculos = filtered.map((procedure, index) => {
            const match = fascicules.find(fascicule => fascicule.id === procedure.id);
            return {
                name: procedure?.name,
                id: match.id,
                id2: procedure?.id,
                amount: match.downloads
            }
        })

        this.setState({ datosFasciculos });
    }

    getCountries = () => {
        let paises = new Set([]), countries = [];
        let { filterUserTypes, allUsers } = this.state
        let filteredUsers = [...allUsers];

        if (filterUserTypes === "Solo No Registrados")
            return [];
        if (filterUserTypes !== "Todos los usuarios")
            filteredUsers = allUsers.filter(user => user.type === mapaFiltro[filterUserTypes]);

        filteredUsers.forEach(user => {
            if (paises.has(user.country)) {
                let pais = countries.find(pais => pais.name === user.country);
                pais.amount++
            }
            else {
                paises.add(user.country);
                countries.push({ name: user.country, amount: 1 })
            }
        })

        let filteredCountries = countries.filter(el => el.amount > 0)
        let countriesSorted = filteredCountries.sort((a, b) => b.amount - a.amount)
        return countriesSorted.slice(0, 20);//Solo regresar los primeros 20 paises de la lista
    }

    handleFiltro = (event) => {
        let filterUserTypes = event.target.value
        this.setState({ filterUserTypes });
        this.cargarDatosGA(filterUserTypes);
    }

    render() {
        const salirGA = this.handleUserLogout.bind(this);
        let state = this.state
        let noLyticsError = (state.error !== "LYTICS")
        return (
            <div className="content-wrapper p-5">
                <div className="row">
                    <div className="col-md-12">

                        <h1 className="mb-4">Estadísticas generales</h1>
                        <div className="lytics-container">
                            <div className="">
                                <div className="d-flex auth align-items-center justify-content-center">

                                    <div className="mx-2 mb-4">
                                        {noLyticsError && !state.signedIn &&
                                            <div className="promptGa"> 
                                                Por favor inicia sesión en Google Analytics con una cuenta autorizada para el proyecto
                                            </div>
                                        }
                                        {!noLyticsError && <div className="errorDiv"> Hubo un error con tu cuenta de Google Analytics, asegúrate de que está autorizada</div>}
                                    </div>

                                    {
                                        <div className="mx-2 mb-4" style={{ display: state.signedIn ? "none" : "block" }}>
                                            <div id="auth-button" ref={this.googleButton} />
                                        </div>
                                    }

                                    {state.signedIn &&
                                        <>
                                            <div className="mx-2 mb-4">
                                                <GoogleButtonLike user={this.googleService.getGoogleCredentials()} />
                                            </div>
                                            <div className="mx-2 mb-4">
                                                <button className="btn btn-primary" onClick={salirGA}>Salir de Google Analytics</button>
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>

                            {noLyticsError && state.signedIn &&
                                <div>
                                    <div className="bg-light pt-4 pb-4">
                                        <div className="row text-center">
                                            <div className="col-md-3 ">
                                                <p className="h1 mb-0">{state.videosVistos}</p>
                                                <p className="mb-0">Videos vistos</p>
                                            </div>
                                            <div className="col-md-3 ">
                                                <p className="h1 mb-0">{state.fasciculosDescargados}</p>
                                                <p className="mb-0">Fascículos descargados</p>
                                            </div>
                                            <div className="col-md-3 ">
                                                <p className="h1 mb-0">{state.usersPremium}</p>
                                                <p className="mb-0">Usuarios Premium Total</p>
                                            </div>
                                            <div className="col-md-3 ">
                                                <p className="h1 mb-0">{state.usersPremium - state.usersPremiumAnual}</p>
                                                <p className="mb-0">Usuarios Premium Mensual</p>
                                            </div>
                                            <div className="col-md-3 ">
                                                <p className="h1 mb-0">{state.usersPremiumAnual}</p>
                                                <p className="mb-0">Usuarios Premium Anual</p>
                                            </div>
                                            <div className="col-md-3 ">
                                                <p className="h1 mb-0">{state.usersGratis}</p>
                                                <p className="mb-0"> Usuarios Gratis</p>
                                            </div>
                                            <div className="col-md-3 ">
                                                <p className="h1 mb-0">{state.previewPage}</p>
                                                <p className="mb-0"> Diplomados previas vistos</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <h1 className="mb-4 pt-4">Estadísticas detalladas</h1>
                                        <div className="bg-light p-4 mb-4">
                                            <div className="form-group">
                                                <label>Filtrar por</label>
                                                <select className="form-control" value={state.filterUserTypes} onChange={this.handleFiltro}>
                                                    {opciones.map((opcion, index) => <option key={index} value={opcion}>{opcion}</option>)}
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    {/*Tablas simples de estadisticas*/}
                                    <div className="row">
                                        <TablaSimple tableName={"Videos más vistos"} headerOne="Nombre" headerTwo="# de consultas" rows={state.datosVideos} />

                                        <TablaSimple tableName={"Fascículos mas descargados"} headerOne="Nombre" headerTwo="# de consultas" rows={state.datosFasciculos} />

                                        <TablaSimple tableName={"Top de países"} headerOne="Nombre" headerTwo="# de consultas" rows={this.getCountries()} />
                                    </div>
                                </div>}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

StatisticsComponent.contextType = CurrentUser;
export default StatisticsComponent;