import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import CurrentUser from './core/PublicUserSessionContext';
import PublicFooterComponent from './core/PublicFooterComponent';
import PublicNavigationComponent from './core/PublicNavigationComponent';
import PublicLandingPage from './PublicLandingPage';
import EventsComponent from './EventsComponent';
import ProceduresComponent from './ProceduresComponent';
import ProcedureComponent from './ProceduresComponent/ProcedureComponent';
import SignUpComponent from './SignUpComponent';
import LoginComponent from '../commons/LoginComponent';
import RecuperarPasswordComponent from '../commons/RecuperarPasswordComponent';
import UserProfileComponent from './UserProfileComponent';
import ProfileUserFormComponent from './UserProfileComponent/ProfileUserFormComponent';
import ProfilePaymentFormComponent from './UserProfileComponent/ProfilePaymentFormComponent';
import PublicFAQComponent from './PublicFAQComponent';
import PublicAboutComponent from './PublicAboutComponent';
import PublicContactComponent from './PublicContactComponent';
import WelcomeComponent from './SignUpComponent/WelcomeComponent';
import PaymentFormComponent from './SignUpComponent/PaymentFormComponent';

import HomeComponent from '../commons/HomeComponent';
import ReasignarPasswordComponent from "../commons/ReasignarPasswordComponent";
import TerminosCondiciones from "../commons/TerminosCondiciones";
import AvisoPrivacidad from "../commons/AvisoPrivacidad";

import DiplomadoPageAdministrar from "./AdminUniversidad/DiplomadoPageAdministrar";
import DiplomadoGridAdministrar from "./AdminUniversidad/DiplomadoGridAdministrar";
import DiplomadoGridComponent from "./DiplomadosComponent/DiplomadoGridComponent";
import DiplomadoPageComponent from "./DiplomadosComponent/DiplomadoPageComponent";
import DiplomadoInternoComponent from "./DiplomadosComponent/DiplomadoInternoComponent";
import DiplomadoPreviewComponent from "./DiplomadosComponent/DiplomadoPreviewComponent";
import DiplomadoSuccessComponent from "./DiplomadosComponent/DiplomadoSuccessComponent";

import AulaPageAdministrar from "./AdminUniversidad/AulaPageAdministrar";
import AulaGridAdministrar from "./AdminUniversidad/AulaGridAdministrar";
import AulaGridComponent from "./AulasComponent/AulaGridComponent";
import AulaPageComponent from "./AulasComponent/AulaPageComponent";

class PublicRouterComponent extends Component {

    constructor() {
        super();
        this.block = false;
        this.state = { showHeader: true };
        this.root = document.getElementById("root");
    }
    componentDidMount() {
        if (!(this.root.scrollHeight - 164 > window.innerHeight)) {
            return;
        }
        this.prev = window.scrollY;

        window.addEventListener('scroll', e => {
            this.handleNavigation(e)
        });
        window.addEventListener('transitionend', () => {
            this.block = false;
        });
    }

    handleNavigation = (e) => {
        const window = e.currentTarget;

        if (this.prev > window.scrollY && !this.block) {
            if (!this.state.showHeader)
                this.showHeader();
            else
                this.prev = window.scrollY;

        } else if (this.prev < window.scrollY && !this.block) {
            if (this.state.showHeader)
                this.hideHeader()
            else
                this.prev = window.scrollY;
        }
    }
    showHeader = () => {
        this.block = true;
        this.setState({ showHeader: true }, () => {
            this.prev = window.scrollY + 164;
        })
    }
    hideHeader = () => {
        this.block = true;
        this.prev = (window.scrollY - 164 < 0) ? 0 : (window.scrollY - 164);
        this.setState({ showHeader: false });
    }

    routesForType = () => {
        const currentUser = this.context.currentUser;
        if (currentUser.type === "Gest") {
            return (
                <React.Fragment>
                    <Route path="/reasignar_password" component={ReasignarPasswordComponent} />
                    <Route exact path="/registro" component={SignUpComponent} />
                    <Route exact path="/login" component={LoginComponent} />
                    <Route exact path="/login/password" component={LoginComponent} />
                    <Route exact path="/login/recuperar_password" component={RecuperarPasswordComponent} />
                </React.Fragment>
            );
        } else if (currentUser.Type === "Admin") {
            return <Route exact path="/login" component={HomeComponent} />
        } else {
            return (
                <React.Fragment>
                    <Route exact path="/login" component={HomeComponent} />
                    <Route exact path="/registro" component={WelcomeComponent} />
                    <Route exact path="/perfil" component={UserProfileComponent} />
                    <Route exact path="/perfil/usuario" component={ProfileUserFormComponent} />
                    <Route exact path="/perfil/pago" component={ProfilePaymentFormComponent} />
                </React.Fragment>
            );
        }
    }
    isScrolling = () => !(this.root.scrollHeight - 164 > window.innerHeight) ? false : true
    userIsPremium = () => ["Premium", "Admin"].includes(this.context.currentUser.type)

    render() {
        const currentUser = this.context.currentUser;
        return (
            <React.Fragment>
                <PublicNavigationComponent showHeader={this.state.showHeader} scrolling={this.isScrolling()} />

                <div className="flex-fill">
                    {this.routesForType()}
                    <Route exact path="/registro-finalizar" component={PaymentFormComponent} />

                    {currentUser.adminAula && <Route exact path="/administrar-aula" component={AulaGridAdministrar} />}
                    {currentUser.adminAula && <Route exact path="/administrar-aula/aula/:id" component={AulaPageAdministrar} />}
                    {currentUser.adminDiplomado && <Route exact path="/administrar-diplomado" component={DiplomadoGridAdministrar} />}
                    {currentUser.adminDiplomado && <Route exact path="/administrar-diplomado/diplomado/:id" component={DiplomadoPageAdministrar} />}
                    {this.userIsPremium() && <Route exact path="/mis-diplomados" component={DiplomadoGridComponent} />}
                    {this.userIsPremium() && <Route exact path="/mis-diplomados/diplomado/:id" component={DiplomadoPageComponent} />}
                    {this.userIsPremium() && <Route exact path="/mis-diplomados/diplomado-CxVet/:id" component={DiplomadoInternoComponent} />}
                    {this.userIsPremium() && <Route exact path="/mis-diplomados/diplomado-preview/:id" component={DiplomadoPreviewComponent} />}
                    {this.userIsPremium() && <Route exact path="/mis-diplomados/finalize/:id" component={DiplomadoSuccessComponent} />}

                    {currentUser.id && <Route exact path="/mis-aulas" component={AulaGridComponent} />}
                    {currentUser.id && <Route exact path="/mis-aulas/aula/:id" component={AulaPageComponent} />}

                    <Route exact path="/" component={PublicLandingPage} />
                    <Route exact path="/index" component={PublicLandingPage} />
                    <Route exact path="/eventos" component={EventsComponent} />
                    <Route exact path="/procedimientos" component={ProceduresComponent} />
                    <Route exact path="/procedimientos/:type/:filter" component={ProceduresComponent} />
                    <Route exact path="/FAQ" component={PublicFAQComponent} />
                    <Route exact path="/about" component={PublicAboutComponent} />
                    <Route exact path="/contacto" component={PublicContactComponent} />
                    <Route exact path="/procedimiento/:id" component={ProcedureComponent} />
                    <Route exact path="/tos" component={TerminosCondiciones} />
                    <Route exact path="/aviso_privacidad" component={AvisoPrivacidad} />
                </div>

                <PublicFooterComponent />
            </React.Fragment>
        );
    }
}
PublicRouterComponent.contextType = CurrentUser;
export default PublicRouterComponent;