import React, { Component } from 'react';
import DeleteAlertCompoment from '../core/DeleteAlertComponent';
import UploadFileAlertComponent from '../core/UploadFileAlertComponent';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import MaskedFormControl from 'react-bootstrap-maskedinput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import CurrentUser from '../../public/core/PublicUserSessionContext';
import { deleteProcedure, getProcedure, getTags, saveProcedureData, uploadDemo, uploadFasicle, uploadPreview, uploadVideo } from '../../../service/procedures';
import {
    SPECIES_TYPES, REQUIRED_INPUT_MESSAGE, VALID_FORMAT_MESSAGE, CXVET_VIDEO_TYPES,
    ACCEPTED_IMAGES, ACCEPTED_VIDEOS, ACCEPTED_FILES, REQUIRED_SELECT_MESSAGE, GRATIS
} from '../../../service/constants';

class ProcedureFormComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            procedureID: this.props.match.params.id,
            editing: this.props.match.params.id ? true : false,
            procedure: {
                name: "",
                type: GRATIS,
                kynd: "",
                date: Date.now(),
                times: [],
                tags: {},
                meta: "",
                demo: "",
                fascicle: "",
                preview: "",
                video: "",
            },
            tags: [],
            errors: {},

            fileStatus: { name: "", state: 0 },
            deleteModalShow: false,
            fileModalShow: false,
        };
    }

    componentDidMount = () => {
        this.getInitialData()
    }
    getInitialData = async () => {
        this.context.iniciaCarga()
        let { procedureID, editing, procedure } = this.state
        let tags = await getTags()
        procedure = editing ? await getProcedure(procedureID) : procedure
        this.setState({ procedure, tags });
        this.context.terminaCarga()
    }

    handleFormSubmit = async (event) => {
        event.preventDefault();
        if (this.validateErrors()) {
            this.context.iniciaCarga()
            const { procedureID, demoFile, fascicleFile, previewFile, videoFile, procedure } = this.state

            let demo = demoFile
                ? await uploadDemo(procedureID, demoFile, (data) => this.setState(data))
                : procedure.demo
            let fascicle = fascicleFile
                ? await uploadFasicle(procedureID, fascicleFile, (data) => this.setState(data))
                : procedure.fascicle
            let preview = previewFile
                ? await uploadPreview(procedureID, previewFile, (data) => this.setState(data))
                : procedure.preview
            let video = videoFile
                ? await uploadVideo(procedureID, videoFile, (data) => this.setState(data))
                : procedure.video
            let data = { ...procedure, demo, fascicle, preview, video }
            await saveProcedureData(procedureID, data)
            this.context.terminaCarga()
            this.props.history.push("/admin/procedures")
        }
    }
    validateErrors = () => {
        let errors = {}, timeErrors = {};
        const required = ["name", "detail", "kynd", "demo", "fascicle", "preview", "video"]

        required.forEach(field => {
            if (field === "kynd" && !this.state.procedure.kynd)
                errors.kynd = REQUIRED_SELECT_MESSAGE
            else if (!this.state.procedure[field])
                errors[field] = REQUIRED_INPUT_MESSAGE
        })

        this.state.procedure.times.map((time, index) => {
            if (!time.name)
                timeErrors[`name_${index}`] = REQUIRED_INPUT_MESSAGE
            if (!time.time)
                timeErrors[`time_${index}`] = REQUIRED_INPUT_MESSAGE
            else {
                var regex = /^([0-9])(:)([0-6][0-9])(:)([0-5][0-9])$/;
                if (!regex.test(time.time))
                    timeErrors[`time_${index}`] = VALID_FORMAT_MESSAGE;
            }
        });
        if (Object.keys(timeErrors).length > 0)
            errors.timeErrors = timeErrors

        this.setState({ errors })
        return Object.keys(errors).length === 0
    }

    handleInputChange = (event) => {
        let { name, value, type, checked } = event.target

        const procedure = { ...this.state.procedure };
        const { [name]: tagName, ...restOfTags } = procedure.tags

        if (type == "checkbox")
            procedure.tags = checked ? { ...restOfTags, [name]: checked } : restOfTags
        else
            procedure[name] = value
        this.setState({ procedure, errors: {} });
    }
    handleFileChange = (event) => {
        const [file, fileType, name] = [event.target.files[0], `${event.target.name}File`, event.target.name]
        let url = URL.createObjectURL(file)
        let procedure = { ...this.state.procedure, [name]: url }
        this.setState({ procedure, [fileType]: file, errors: {} })
    }
    handleTimeChange = (event) => {
        const { name, value, id, index = id } = event.target
        let procedure = { ...this.state.procedure }
        procedure.times[index][name] = value;
        this.setState({ procedure, errors: {} });
    }

    addTime = () => {
        const procedure = { ...this.state.procedure };
        procedure.times.push({ "name": "", "time": "" });
        procedure.timeName = "";
        procedure.time = "";
        this.setState({ procedure });
    }
    deleteTime = (index) => {
        const procedure = { ...this.state.procedure };
        procedure.times.splice(index, 1);
        this.setState({ procedure, errors: {} });
    }

    deleteProcedure = async () => {
        this.context.iniciaCarga()
        await deleteProcedure(this.state.procedureID)
        this.props.history.push("/admin/procedures")
        this.context.terminaCarga()
    }

    render() {
        const state = this.state
        const { errors, procedure } = state
        const timeErrors = errors.timeErrors || {}
        return (
            <Form onSubmit={this.handleFormSubmit} >
                <div className="content-wrapper p-5">
                    <div className="row">
                        <div className="col-md-12">

                            <h1 className="mb-0">Cirugías</h1>
                            <h3 className="mb-5">Nueva cirugía</h3>
                            <div className="row justify-content-center mb-4">

                                <div className="col-md-10">
                                    <h4>Información general de la cirugía</h4>
                                    <div className="form-container">
                                        <div className="row">
                                            <div className="col-md-12">

                                                <div className="form-group">
                                                    <label>Nombre</label>
                                                    <input name="name" onChange={this.handleInputChange} value={procedure.name} type="text" className="form-control" />
                                                    <Alert show={!!errors.name} variant="danger">{errors.name}</Alert>
                                                </div>
                                                <div className="form-group">
                                                    <label>Tipo de usuario</label>
                                                    <select name="type" onChange={this.handleInputChange} value={procedure.type} className="form-control">
                                                        {CXVET_VIDEO_TYPES.map(type => <option key={type} value={type}> {type} </option>)}
                                                    </select>
                                                </div>
                                                <div className="form-group">
                                                    <label>Descripción</label>
                                                    <textarea name="detail" onChange={this.handleInputChange} value={procedure.detail} className="form-control" rows="3" />
                                                    <Alert show={!!errors.detail} variant="danger">{errors.detail}</Alert>
                                                </div>
                                                <div className="form-group">
                                                    <label>Especie</label>
                                                    <select name="kynd" onChange={this.handleInputChange} value={procedure.kynd} className="form-control" >
                                                        <option key="0" value=""> Seleccionar </option>
                                                        {SPECIES_TYPES.map(type => <option key={type} value={type}> {type} </option>)}
                                                    </select>
                                                    <Alert show={!!errors.kynd} variant="danger">{errors.kynd}</Alert>
                                                </div>

                                                <div className="form-group">
                                                    <label>Etiquetas</label>
                                                    <div className="d-block">
                                                        {state.tags.map(tag =>
                                                            <div key={tag.id} className="form-check form-check-inline">
                                                                <input name={tag.id} checked={procedure.tags[tag.id]} onChange={this.handleInputChange} type="checkbox" className="form-check-input" />
                                                                <label className="form-check-label" >{tag.name}</label>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <label>Meta</label>
                                            <input name="meta" onChange={this.handleInputChange} value={procedure.meta} type="text" className="form-control" placeholder="sinonimo1, sinonimo2, sinonimo3" />
                                            <Alert show={!!errors.meta} variant="danger">{errors.meta}</Alert>
                                        </div>

                                        <div className="row mt-4">
                                            <div className="col-md-12">
                                                <h4>Archivos de la cirugía</h4>
                                            </div>
                                            <div className="col-md-4 col-sm-6">
                                                <div className="form-group">
                                                    <label >Imagen previa </label>
                                                    <img className="img-fluid  mb-2 preview" src={procedure.preview} />
                                                    <input name="preview" onChange={this.handleFileChange} type="file" className="form-control-file" accept={ACCEPTED_IMAGES} />
                                                    <Alert show={!!errors.preview} variant="danger">{errors.preview}</Alert>
                                                    <small className="form-text text-muted">La imagen debe tener dimensiones mínimas de 1090px x 720px</small>
                                                </div>
                                            </div>

                                            <div className="col-md-4 col-sm-6">
                                                <div className="form-group">
                                                    <label>Video demo</label>
                                                    <video className="w-100 mb-2" controls src={procedure.demo} type="video/mp4" />
                                                    <input name="demo" onChange={this.handleFileChange} type="file" className="form-control-file" accept={ACCEPTED_VIDEOS} />
                                                    <Alert show={!!errors.demo} variant="danger">{errors.demo}</Alert>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-4">
                                                <div className="form-group">
                                                    <label >Guía descargable en PDF</label>
                                                    {procedure.fascicle &&
                                                        <object type="application/pdf" data={procedure.fascicle} width="400" height="400" />}
                                                    <input name="fascicle" onChange={this.handleFileChange} type="file" className="form-control-file" accept={ACCEPTED_FILES} />
                                                    <Alert show={!!errors.fascicle} variant="danger">{errors.fascicle}</Alert>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label >Video completo</label>
                                                    <video className="w-100 mb-2" controls src={procedure.video} type="video/mp4"></video>
                                                    <input name="video" onChange={this.handleFileChange} type="file" className="form-control-file" accept={ACCEPTED_VIDEOS} />
                                                    <Alert show={!!errors.video} variant="danger">{errors.video}</Alert>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Marcadores de video</label>
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th scope="">Nombre</th>
                                                                <th scope="">Tiempo</th>
                                                                <th scope=""></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody >
                                                            {procedure.times.map((time, index) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        <td>
                                                                            <input name="name" id={index} onChange={this.handleTimeChange} value={time.name} type="text" className="form-control" />
                                                                            <Alert show={!!timeErrors[`name_${index}`]} variant="danger">{timeErrors[`name_${index}`]}</Alert>
                                                                        </td>
                                                                        <td>{/*For MaskedFormControl, id must be a string value*/}
                                                                            <MaskedFormControl name="time" id={`${index}`} onChange={this.handleTimeChange} value={time.time} type="text" className="form-control" placeholder="hh:mm:ss" mask='1:11:11' />
                                                                            <Alert show={!!timeErrors[`time_${index}`]} variant="danger">{timeErrors[`time_${index}`]}</Alert>
                                                                        </td>
                                                                        <td>
                                                                            <a className="text-light btn btn-primary" onClick={() => this.deleteTime(index)}>
                                                                                <FontAwesomeIcon icon={faTrash} />
                                                                            </a>
                                                                        </td>
                                                                    </tr>)
                                                            })}
                                                        </tbody>
                                                    </table>

                                                    <button className="btn btn-primary btn-block" type="button" onClick={this.addTime}>Agregar nuevo marcador</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="btn-row" >
                                {state.editing && <button type="button" onClick={() => this.setState({ deleteModalShow: true })} className="btn btn-primary">Eliminar</button>}
                                <button type="button" className="btn btn-primary" onClick={this.props.history.goBack}>Cancelar</button>
                                <button type="submit" className="btn btn-primary" >Guardar</button>
                            </div>
                        </div>

                    </div>
                </div>

                <DeleteAlertCompoment show={state.deleteModalShow} onHide={() => this.setState({ deleteModalShow: false })} deleteElement={this.deleteProcedure} />
                <UploadFileAlertComponent show={state.fileModalShow} onHide={() => this.setState({ fileModalShow: false })} file={state.fileStatus} />
            </Form>
        );
    }
}
ProcedureFormComponent.contextType = CurrentUser;
export default ProcedureFormComponent;