
/**
 * @typedef {import("../google-analytics").GoogleAnalyticsReportSDK} GoogleAnalyticsReportSDK
 */

/**
 * @template T
 * @abstract
 * @class
 */
export class Report {

    /**
     * @param {GoogleAnalyticsReportSDK} googleAnalyticsSdk
     */
    constructor(googleAnalyticsSdk) {
        this.googleAnalyticsSdk = googleAnalyticsSdk;
    }

    /**
     * @param {string} startDate 
     */
    startDate(startDate) {
        this._startDate = startDate;
        return this;
    }

    /**
     * @param {string} endDate 
     */
    endDate(endDate) {
        this._endDate = endDate;
        return this;
    }

    /** @return {Promise<T>} */
    async run() {
        return Promise.reject(new Error("Not implemented"));
    }

    /**
     * @param {Record<string, any>} params 
     */
    filterEmptyParams(params) {
        return Object.keys(params).reduce((acc, key) => {
            if (params[key] !== undefined && params[key] !== null) {
                acc[key] = params[key];
            }
            return acc;
        }, /** @type {Record<String, any>} */({}));
    }

    getDateRanges() {
        if (!this.usesRangeDates()) return;

        return [
            { startDate: this._startDate, endDate: this._endDate }
        ];
    }

    usesRangeDates() {
        return this._startDate && this._endDate;
    }
}