import React, { Component } from "react";
import CurrentUser from "./PublicUserSessionContext";
import { development_plan_id, development_annual_plan_id } from '../../../resources/config/keys'
import firebase from 'firebase';

class ContPaypal extends Component {

  componentDidMount() {
    let that = this;
    window.paypal.Buttons(
      {
        style: { label: "pay" },
        createSubscription: function (data, actions) {
          return new Promise((resolve, reject) => {
            actions.subscription.create({ "plan_id": that.props.planAnual ? development_annual_plan_id : development_plan_id, })
              .then(d => {
                that.props.registerSubscriptionID(d)
                resolve(d)

              }).catch(error => {
                console.error("OCURRIO UN ERROR AL CREAR SUSCRIPCION");
                console.error(error);
                this.context.mostrarError("Error Paypal", "No se pudo crear la suscripción");
                reject(error)
              })
          })
        },

        onApprove: function (data, actions) {
          that.props.registrarPago(data.subscriptionID);
        },
        onError: function (err) {
          console.error("OCURRIO UN ERROR AL CREAR SUSCRIPCION");
          console.error(err);
          this.context.mostrarError("Error Paypal", "No se pudo crear la suscripción");
        }

      }
    ).render('#paypal-button-container');
  }

  render() {
    return (<div>
      <div id="paypal-button-container"></div>
    </div>)
  }
}
ContPaypal.contextType = CurrentUser;
export default ContPaypal;