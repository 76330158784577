import axios from "axios";

const version = "v1beta"

const ENDPOINT = {
    SERVICE: `https://analyticsdata.googleapis.com/${version}`,
    REPORT: {
        RUN: "properties/{property}:runReport",
    }
}

export class GoogleAnalyticsReportSDK {
    
    /**
     * @param {{
     *  credentials: string,
     *  property: number|string,
     * }} param0 
     */
    constructor({ credentials, property }) {
        this.credentials = credentials;
        this.property = property;
    }

    /**
     * @typedef {{
     *  headers: {
     *      "Authorization": string,
     *      [key: string]: string
     *  },
     * }} RequestOptions
     * 
     * @typedef {RequestOptions & {
     *  method: 'POST',
     *  body?: any,     
     * }} PostRequestOptions
     * 
     * @typedef {RequestOptions & {
     *  method: 'GET',
     * }} GetRequestOptions
     * 
     * @param {string} url 
     * @param {PostRequestOptions|GetRequestOptions} options 
     * @returns 
     */
    async _request(url, options) {
        const baseHeaders = {
            "Content-Type": "application/json",
        }

        const headers = {
            ...baseHeaders,
            ...options.headers,
        }

        try {
            const response = await axios({
                method: options.method,
                url,
                headers,
                // @ts-ignore
                data: options.body,
            });

            return {
                success: true,
                status: response.status,
                data: response.data,
            }
        } catch (error) {
            console.log({
                error
            });
            const status = error instanceof Error ? error.message : "Unknown error";

            return {
                success: false,
                status: status,
                data: null,
            }
        }
    }

    /**
     * @param {Record<string, string>} headers 
     * @returns {{
     *  "Authorization": string,
     *  [key: string]: string
     * }}
     */
    withAuth(headers) {
        const auth = `Bearer ${this.credentials}`;

        return {
            ...headers,
            "Authorization": auth,
        }
    }

    /**
     *  @typedef {Omit<GetRequestOptions, "headers"> & Partial<Pick<GetRequestOptions, "headers">>} OptionalHeaderGetRequest
     *  @typedef {Omit<PostRequestOptions, "headers"> & Partial<Pick<PostRequestOptions, "headers">>} OptionalHeaderPostRequest
     * 
     * @param {string} path 
     * @param {OptionalHeaderGetRequest|OptionalHeaderPostRequest} options 
     * @returns 
     */
    request(path, options) {
        return this._request(`${ENDPOINT.SERVICE}/${path}`, {
            ...options,
            headers: this.withAuth(options.headers ?? {}),
        });
    }

    /**
     * @typedef {{
     *  dimensionHeaders: Array<{ name: string }>,
     *  metricHeaders: Array<{ name: string, type: string }>,
     *  rows: Array<{
     *      dimensionValues: Array<{ value: string }>,
     *      metricValues: Array<{ value: string }>
     *  }>,
     *  rowCount: number,
     *  metadata: {
     *      currencyCode: string,
     *      timeZone: string
     *  },
     *  kind: string
     * }} RunReportResponse
     * 
     * @param {Record<string, any>} config 
     * @returns {Promise<RunReportResponse>}
     */
    async run(config) {
        const { success, status, data } = await this.request(
            route(ENDPOINT.REPORT.RUN, { property: this.property }), 
            {
                method: "POST",
                body: config,
            }
        );

        if (! success) {
            throw new Error(`Failed to run report: ${status}`);
        }

        return data;
    }
 
}

/**
 * Replaces placeholders in the path with the provided parameters.
 * @param {string} path
 * @param {Record<string, any>} params
 * @return {string}
 */
function route(path, params) {
    const keys = Object.keys(params);
    const needles = path.match(/{\w+}/g);

    let result = path;

    if (needles) {
        needles.forEach((needle) => {
            const key = needle.replace(/{|}/g, "");
            
            if (! keys.includes(key)) {
                throw new Error(`Missing parameter: ${key} on route: ${path}`);
            }

            result = result.replace(needle, params[key]);
        });
    }

    return result;
}

