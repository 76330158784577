import React, { Component } from 'react';
import firebase from 'firebase';
import { Redirect } from 'react-router-dom';
import CurrentUser from "../core/PublicUserSessionContext";
import ContPaypal from "../core/ContPaypal";
import Beneficios from "./Beneficios";
import ModalConfirmacion from "./ModalConfirmacion";
import moment from "moment";
import 'moment/locale/es';
moment.locale("es");

class ProfilePaymentFormComponent extends Component {
    constructor(props) {
        super(props);
        this.state = { user: { paymentCard: {}, hasPremiumAnual: false }, redirect: false, mostrarConfirmacion: false, planAnual: false };
    }

    componentDidMount = () => {
        let that = this;
        firebase.auth().onAuthStateChanged((currentUser) => {
            if (currentUser) {
                firebase.firestore().collection("users").doc(currentUser.uid).get()
                    .then((doc) => {
                        if (doc.exists) {
                            let data = doc.data();
                            this.setState({
                                user: { id: doc.id, userName: doc.data().name, ...doc.data(), hasPremiumAnual: doc.data().hasPremiumAnual || false },
                                oldType: data.type, oldStatusPago: data.statusPago, oldEmail: this.state.user.email, planAnual: doc.data().hasPremiumAnual
                            });
                        } else {
                            this.setState({ currentUser });
                        }
                    }).catch(error => {
                        console.error("Error al recuerar usuario: ", error);
                        that.context.mostrarError("No se pudo recuperar usuaro", "ERROR PERFIL");

                    })
            } else {
            }
        });
    }

    saveUser = () => {
        let that = this;

        let user = this.state.user;
        user.hasPremiumAnual = this.state.planAnual
        const db = firebase.firestore();

        this.context.iniciaCarga();
        let updateUserMembershipFunction = firebase.functions().httpsCallable('updateUserMembership');
        updateUserMembershipFunction({ user, uid: user.id })
            .then((docRef) => {

                if (user.type == "Gratis") {
                    let copia = Object.assign({}, this.state.user);
                    copia.statusPago = "POR_TERMINAR";
                    that.setState({ user: copia })
                    this.context.mostrarError("Se conservará la membresía Premium hasta la próxima fecha de pago", "Cambio exitoso");
                }
                this.setState({ redirect: true });
                this.props.updateType(user.type)
                if (this.state.oldType !== user.type) {
                    let actionType = user.type === "Gratis" ? "DOWNGRADE" : "UPGRADE";
                    let accountType = user.type === "Gratis" ? "MANUAL" : "Premium";
                    const updateAccountEmailFunction = firebase.functions().httpsCallable('updateAccountEmail');
                    let data = {
                        accountType: accountType,
                        actionType: actionType,
                        emailAddress: user.email,
                        nombreCliente: user.name,
                        fechaPago: Date.now(),
                        planAnual: user.hasPremiumAnual
                    }

                    updateAccountEmailFunction(data).then((result) => {
                    }).catch((error) => {
                        console.error(error);
                    });
                }

            }).catch(function (error) {
                console.error("Error updating user: " + error);
                let copia = Object.assign({}, that.state.user);
                copia["type"] = that.state.oldType;
                copia["statusPago"] = that.state.oldStatusPago;
                that.setState({ user: copia });
                if (error.message === "SUBSCRIPTION_UNMAPPED_ERROR") {
                    that.context.mostrarError("Por el momento no es posible suspender tu suscripción, porfavor espera unos minutos e intenta de nuevo", "ERROR PERFIL");
                } else {
                    that.context.mostrarError("No se pudo guardar perfil", "ERROR PERFIL");
                }

            }).finally(el => {
                db.collection("users").doc(that.state.user.id).get().then(snap => {
                    let data = snap.data();
                    data.id = that.state.user.id;
                    that.setState({ user: data }, () => {
                        that.context.terminaCarga();
                    });
                }).catch(err => { console.error("ERROR getting updated user doc: " + err) });

            })
    }

    registrarPago = (subscriptionId) => {
        let copia = Object.assign({}, this.state.user);
        copia["subscriptionId"] = subscriptionId;
        copia["type"] = "Premium";
        this.setState({ user: copia }, () => {
            this.saveUser();
        });
    }
    reactivarCuenta = () => {
        let copia = Object.assign({}, this.state.user);
        copia["type"] = "Premium";
        copia["statusPago"] = "BIEN";
        this.setState({ user: copia }, () => {
            this.saveUser();
        })
    }
    confirmar = () => {
        const user = Object.assign({}, this.state.user);
        user.type = "Gratis";
        user.statusPago = "POR_TERMINAR";
        this.setState({ mostrarConfirmacion: false, user: user }, () => {
            this.saveUser();
        })
    }
    cancelar = () => {
        this.setState({ mostrarConfirmacion: false })
    }
    handlePaymentTypeChange = (event) => {
        this.setState({ planAnual: event.target.value == "PremiumAnual" })
    }

    registerSubscriptionID = (subscriptionId) => {

        firebase.firestore().collection("users").doc(this.state.user.id).update({
            subscriptionId: subscriptionId
        })
    }
    render() {

        const user = this.state.user;

        let diaProxPago = user.paymentDay === "Sin cobro" ? "" : obtenerFechaPago(user.paymentDay, this.state.user.hasPremiumAnual);
        let diaTerminacion = user.type === "Legacy" ? moment(user.terminationDate).format("LL") : "";
        return (


            <div className="tarjeta-bold p-3">
                {this.state.redirect && <Redirect to="/perfil" />}
                <ModalConfirmacion show={this.state.mostrarConfirmacion} confirmar={this.confirmar} cancelar={this.cancelar} />

                {(user.type === "Premium" || user.type === "Legacy") ?
                    (user.statusPago === "POR_TERMINAR" || user.statusPago === "CANCELLED") ?
                        <React.Fragment>
                            <p className="h4">Membresía actual</p>
                            <p className="h6">Ahora cuentas con la <span className="font-weight-bold">Membresía Básica</span> de CxVet. Mantendrás los beneficios de tu anterior membresía hasta el día {diaProxPago}. Vuelve a contratar <span className="font-weight-bold">Premium</span> y disfruta de todos los beneficios de CxVet</p>
                            <p className="h4 mt-3">Ventajas de contratar <span className="font-weight-bold">Premium</span></p>
                            <Beneficios />
                            <p className="h4">Contrata Premium ahora</p>
                            {user.statusPago === "POR_TERMINAR" ?
                                <button className="btn btn-secondary btn-block" onClick={this.reactivarCuenta}>Reactiva tu membresiá premium</button>
                                : <>
                                    <div className="form-payment">
                                        <h4>Membresía</h4>
                                        <div className="form-group">
                                            <label>Tipo de membresía elegida</label>
                                            <select className="form-control" onChange={this.handlePaymentTypeChange} name="type" value={this.state.planAnual ? "PremiumAnual" : "Premium"}>
                                                <option value="Premium">Membresia Premium Mensual ($9.99 USD / mes)</option>
                                                <option value="PremiumAnual">Membresia Premium Anual ($99.90 USD / año)</option>
                                            </select>
                                        </div>
                                    </div>
                                    <p className="font-weight-bold ">{this.state.user.hasPremiumAnual ? "Precio  $99.90 USD/año" : "Precio  $9.99 USD/mes"}</p>
                                    <ContPaypal registerSubscriptionID={this.registerSubscriptionID} registrarPago={this.registrarPago} planAnual={this.state.planAnual} />
                                </>
                            }


                        </React.Fragment>
                        :
                        (user.type === "Legacy") ?
                            <React.Fragment>
                                <p className="h4">Membresía actual</p>
                                <p className="h6">Ahora cuentas con la <span className="font-weight-bold">Membresía Premium</span> de CxVet. Mantendrás estos beneficios hasta el día {diaTerminacion}.</p>
                            </React.Fragment>
                            :
                            <React.Fragment>
                                <p className="h4">Membresía actual</p>
                                <p className="h6">Cuentas con la <span className="font-weight-bold">Membresía Premium {this.state.user.hasPremiumAnual ? "anual" : ""}</span> de CxVet</p>
                                <p className="h6">Tu próxima fecha de cobro es {diaProxPago}</p>
                                <p className="">{this.state.user.hasPremiumAnual ? "Precio  $99.90 USD/año" : "Precio  $9.99 USD/mes"}</p>
                                <button className="btn btn-secondary btn-block" onClick={() => { this.setState({ mostrarConfirmacion: true }) }}>Cambiar mi membresía a Básica</button>

                            </React.Fragment>
                    :
                    <React.Fragment>
                        <p className="h4">Membresía actual</p>
                        <p className="h6">Cuentas con la <span className="font-weight-bold">Membresía Básica</span> de CxVet. Conviértela a Premium y disfruta de todos los beneficios de CxVet.</p>
                        <p className="h4 mt-3">Ventajas de contratar <span className="font-weight-bold">Premium</span></p>
                        <Beneficios />
                        <p className="h4">Contrata Premium ahora</p>
                        <div className="form-payment">
                            <h4>Membresía</h4>
                            <div className="form-group">
                                <label>Tipo de membresía elegida</label>
                                <select className="form-control" onChange={this.handlePaymentTypeChange} name="type" value={this.state.planAnual ? "PremiumAnual" : "Premium"}>
                                    <option value="Premium">Membresia Premium Mensual ($9.99 USD / mes)</option>
                                    <option value="PremiumAnual">Membresia Premium Anual ($99.90 USD / año)</option>
                                </select>
                            </div>
                        </div>
                        <p className="mb-4 h6">{this.state.planAnual ? "Precio  $99.90 USD/año" : "Precio  $9.99 USD/mes"}</p>
                        <ContPaypal registerSubscriptionID={this.registerSubscriptionID} registrarPago={this.registrarPago} planAnual={this.state.planAnual} />
                    </React.Fragment>
                }
            </div>

        );
    }
}
function obtenerFechaPago(payDay, hasPremiumAnual) {
    let thirtyDays = 2592000000
    let oneYear = 31556926000
    let terminationDate = payDay
    let rightNow = Date.now()
    while (terminationDate <= rightNow) {
        if(hasPremiumAnual){
            terminationDate += oneYear
        }else{
            terminationDate += thirtyDays
        }
        
    }
    let fechaHoy = moment(terminationDate);
    return fechaHoy.format("LL");
}
ProfilePaymentFormComponent.contextType = CurrentUser;
export default ProfilePaymentFormComponent;

