export class GoogleService {
    /** @type {google} */
    google;

    /** 
     * @typedef {(response: google.accounts.id.UserProfile & { credential?: string }) => void} onSignInCallback
     * @type {onSignInCallback | undefined} 
     */
    onSignIn;
    
    /** 
     * @typedef {() => void} onSignOutCallback
     * @type {onSignOutCallback | undefined} 
     */
    onSignOut;

    /**
     * @param {{
     *  clientId: string
     * }} param0
     */
    constructor({
        clientId
    }) {
        this.google = window.google;
        this.clientId = clientId;
    }
    
    /**
     * @param {{
     *  buttonElement?: HTMLButtonElement
     * }} param0 
     */
    initializeAuth({
        buttonElement,
    }) { 
        this.google.accounts.id.initialize({
            client_id: this.clientId,
            callback: this.handleCredentialsResponse.bind(this),
        });
        
        if (buttonElement) {
            this.renderGoogleButton(buttonElement);
        }
    }

    /**
     * Registers a callback for the specified event.
     * 
     * @overload
     * @param {"signIn"} event - The name of the event.
     * @param {onSignInCallback} cb - The callback to handle the "signIn" event.
     * @returns {void}
     * 
     * @overload
     * @param {"signOut"} event - The name of the event.
     * @param {onSignOutCallback} cb - The callback to handle the "signOut" event.
     * @returns {void}
     * 
     * @param {"signIn"|"signOut"} event - The name of the event.
     * @param {onSignInCallback|onSignOutCallback} cb - The callback to handle the event.
     * @returns {void}
     */
    on(event, cb) {
        switch (event) {
            case "signIn":
                this.onSignIn = cb;
                break;
            case "signOut":
                // @ts-expect-error
                this.onSignOut = cb;
                break;
            default:
                break;
        }
    }

    /**
     * @param {google.accounts.id.CredentialResponse} response
     */
    handleCredentialsResponse(response) {
        if (response.credential) {
            this.storeGoogleCredentials(this.parseJwt(response.credential));
            this.requestGoogleClientAccessToken().then(accessToken => {
                this.storeGoogleClientAccessToken(accessToken);
                this.handleSignIn();
            }).catch(() => {
                this.handleSignOut();
            });
        }
    }

    /**
     * @param {string} token 
     */
    parseJwt(token) {
        try {
            const base64Url = token.split('.')[1];
            if (!base64Url) throw new Error("Invalid token format");

            const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            const jsonPayload = decodeURIComponent(
                atob(base64)
                    .split('')
                    .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
                    .join('')
            );
    
            return {
                ...JSON.parse(jsonPayload),
                credential: token,
            };
        } catch (error) {
            console.error("Failed to parse JWT:", error);
            return null; 
        }
    }

    /**
     * @returns {Promise<string>}
     */
    requestGoogleClientAccessToken() {
        return new Promise((resolve, reject) => {
            this.getTokenClient({
                onSuccess: (response) => {
                    if (response.error) reject(response.error);
    
                    const accessToken = response.access_token;
                    resolve(accessToken);
                },
                onError: (error) => {
                    console.log({ error });
                    reject(error);
                }
            }).requestAccessToken();
        });
    }

    /**
         * @param {{
         *  onSuccess: (response: google.accounts.oauth2.TokenResponse) => void,
         *  onError: (error: any) => void,
         * }} param0
         * @returns 
         */
        getTokenClient({ onSuccess, onError }) {
            const google = window.google;
            const hint = this.getGoogleCredentials()?.email;
    
            return google.accounts.oauth2.initTokenClient({
                client_id: this.clientId,
                scope: 'https://www.googleapis.com/auth/analytics.readonly',
                prompt: "",
                login_hint: hint,
                callback: onSuccess,
                error_callback: onError,
            })
        }

    /**
     * @param {string} accessToken 
     */
    storeGoogleClientAccessToken(accessToken) {
        localStorage.setItem("googleAccessToken", accessToken);
    }

    deleteGoogleClientAccessToken() {
        localStorage.removeItem("googleAccessToken");
    }

    getGoogleClientAccessToken() {
        return localStorage.getItem("googleAccessToken");
    }

    handleSignIn() {
        if (this.onSignIn) {
            const googleJwt = this.getGoogleClientAccessToken();
            const googleUserProfile = this.getGoogleCredentials();

            if (!googleJwt || !googleUserProfile) return;

            this.onSignIn({
                ...googleUserProfile,
                credential: googleJwt,
            });
        }
    }

    handleSignOut() {
        if (! this.userIsSignedInGoogleServices()) return;

        this.logoutGoogleServices();
        this.deleteGoogleCredentials();
        this.deleteGoogleClientAccessToken();

        if (this.onSignOut) {
            this.onSignOut();
        }
    }

    /**
     * @param {HTMLButtonElement} buttonElement
     */
    renderGoogleButton(buttonElement) {
        this.google.accounts.id.renderButton(
            buttonElement, 
            { type: "standard", theme: "outline", size: 'large', locale: 'es' } 
        );
    }

    userIsSignedInGoogleServices = () => {
        return this.getGoogleCredentials() !== null;
    }

    /**
     * Stores the Google credentials to
     * know in subsequent requests that the user 
     * is authenticated in Google services.
     * @param {string} credential 
     */
    storeGoogleCredentials = (credential) => {
        localStorage.setItem("googleCredential", JSON.stringify(credential));
    }

    /**
     * @returns {google.accounts.id.UserProfile|null}
     */
    getGoogleCredentials = () => {
        const user = localStorage.getItem("googleCredential");

        if (!user) return null;

        return JSON.parse(user);
    }

    deleteGoogleCredentials = () => {
        localStorage.removeItem("googleCredential");
    }

    logout() {
        this.handleSignOut();
    }

    logoutGoogleServices() {
        const credentials = this.getGoogleCredentials();
        this.google.accounts.id.disableAutoSelect();
        
        if (! credentials || !credentials?.email) return;

        this.google.accounts.id.revoke(credentials?.email ?? "", (e) => console.log(e));
    }
}

