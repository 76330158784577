import { Report } from "./Report";

/**
 * Report to get the count of the diplomado preview page views
 * @typedef {{
 *  id: string;
 *  views: number;
 * }} Video
 * @extends {Report<Video[]>}
 */
export class MostViewedVideosReport extends Report {
    /**
     * @param {import("./Report").GoogleAnalyticsReportSDK} googleAnalyticsSdk
     */
    static make(googleAnalyticsSdk) {
        return new MostViewedVideosReport(googleAnalyticsSdk);
    }

    /**
     * @type {Report<Video[]>["run"]}
     */
    async run() {
        try {
            const data = await this.googleAnalyticsSdk.run(this.filterEmptyParams({
                dateRanges: this.getDateRanges(),
                dimensions: [{ name: "eventName" }, { name: "customEvent:event_label" }],
                metrics: [{ name: "eventCount" }],
                dimensionFilter: {
                    filter: {
                        fieldName: "eventName",
                        stringFilter: {
                            matchType: "EXACT",
                            value: "VIDEOS"
                        }
                    }
                }
            }));

            if (!data.rows) return [];

            return data.rows.map((row) => {
                return {
                    id: row.dimensionValues[1].value,
                    views: Number(row.metricValues[0].value)
                };
            });
        } catch (error) {
            console.error(error);
            throw new Error("Failed to fetch video views data");
        }
    }
}