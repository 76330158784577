import { Report } from "./Report";

/**
 * Report to get the count of the diplomado preview page views
 * @extends {Report<number>}
 */
export class DiplomadoPreviewCountReport extends Report {
    /**
     * @param {import("./Report").GoogleAnalyticsReportSDK} googleAnalyticsSdk
     */
    static make(googleAnalyticsSdk) {
        return new DiplomadoPreviewCountReport(googleAnalyticsSdk);

    }

    /**
     * @type {Report<number>["run"]}
     */
    async run() {
        try {
            const data = await this.googleAnalyticsSdk.run(this.filterEmptyParams({
                dateRanges: this.getDateRanges(),
                dimensions: [{ name: "eventName" }],
                metrics: [{ name: "eventCount" }],
                dimensionFilter: {
                    filter: {
                        fieldName: "eventName",
                        stringFilter: {
                            matchType: "EXACT",
                            value: "DIPLOMADO_PREVIEW_PAGE"
                        }
                    }
                }
            }));

            if (!data.rows) return 0;

            return Number(data.rows[0].metricValues[0].value);
        } catch (error) {
            console.error(error);
            throw new Error("Failed to fetch diplomado preview views data");
        }
    }
}